 

<template>
  <div>
    <!-- Header -->
    <div class="header bg-primary py-6 py-lg-6 pt-lg-6">
      <b-container>
        <div class="header-body text-center mb-1">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5 mb-2">
              <img style="width:100px;" src="@/assets/img/zulal.svg" class=" border-0  bg-primary navbar-brand-img" alt="QNL logo">

            </b-col>
          </b-row>
        </div>
      </b-container>

    </div>
    <!-- Page content -->
    <b-container class="mt--6 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-light border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
            <p v-if="isBusy">Processsing...</p>
             <p v-if="isSuccess">You can login now in Zulal App with your new password</p>
             <p v-if="isErrored">This reset link is no longer valid</p>
              <validation-observer 
              v-if="!isSuccess && !isErrored && !isBusy"
              v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(resetPassword)">
                  <base-input
                      type="password"
                      :name="'password'"
                      label="New password"
                      :required="true"
                      placeholder="New password"
                      v-model="resetForm.password"
                    >
                    </base-input>

                
                  <div class="text-center">
                    <base-button  type="primary" native-type="submit" class="my-4">
                    Reset Password
                    </base-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
         
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios"
import { Router } from 'vue-router';
import { handleError, successMessage } from "@/util/error-handler";

export default {
  name: 'ResetPassword',
  data() {
    return {
      isSuccess: false,
      isBusy: false,
      isErrored: false,
      resetForm: {
        password: ''
      },
      token: ''
    };
  },
  methods: {
    async resetPassword() {
      this.isBusy = true;
      try {
        const response = await axios.put(process.env.VUE_APP_API_URL + 'mobile/guest/reset-password', {
          token: this.$route.query.token,
          email: this.$route.query.email,
          password: this.resetForm.password
        });

        this.isBusy = false;
        this.isSuccess = true;
      } catch (e) {
        this.isBusy = false;
          handleError(e);
      } finally {
        this.isBusy = false;
      }
    }
  },
  async mounted() {
    try {
     this.isBusy = true;
    this.token = this.$route.query.token;
   const response = await axios.post(process.env.VUE_APP_API_URL + 'guest/check-guest-token', {
          token: this.$route.query.token,
          email: this.$route.query.email,
        });
     this.isBusy = false;
    console.log(response)
    
    }
    catch (e) {
       this.isErrored = true;
      } finally {
        this.isBusy = false;
      }
    }
};
</script>